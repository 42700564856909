import { FC, useEffect, useRef, useState } from 'react';
import { DateRangeFiltersProps } from './DateRangeFilters.d';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { DateInput } from 'components/common/Input/DateInput/DateInput';

export const DateRangeFilters: FC<DateRangeFiltersProps> = ({ id, label }) => {
  const { pathname } = useLocation();
  const { customNavigate } = useNavigation();
  const [searchParams] = useSearchParams();
  const isUpdatingFromUrl = useRef(false);

  const formatDate = (date: Date | null): string => {
    if (!date) return '';
    try {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Date formatting error:', error);
      return '';
    }
  };

  const parseDate = (dateStr: string): Date | null => {
    if (!dateStr) return null;
    try {
      const date = new Date(dateStr);
      return isNaN(date.getTime()) ? null : date;
    } catch {
      return null;
    }
  };

  const getInitialDates = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const dateParam = searchParams.get(id);

    if (!dateParam) return { startDate: null, endDate: null };

    const [startDateStr, endDateStr] = dateParam.split('_');
    return {
      startDate: startDateStr ? parseDate(startDateStr) : null,
      endDate: endDateStr ? parseDate(endDateStr) : null,
    };
  };

  const { startDate: initialStartDate, endDate: initialEndDate } =
    getInitialDates();
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);

  const updateURL = (startDate: Date | null, endDate: Date | null) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (!startDate && !endDate) {
      searchParams.delete(id);
    } else {
      const dateParam = `${startDate ? formatDate(startDate) : ''}_${
        endDate ? formatDate(endDate) : ''
      }`;
      searchParams.set(id, dateParam);
    }

    customNavigate(pathname + '?' + searchParams.toString());
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    updateURL(date, endDate);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    updateURL(startDate, date);
  };

  useEffect(() => {
    isUpdatingFromUrl.current = true;

    const filterValue = searchParams.get(id);
    if (!filterValue) {
      setStartDate(null);
      setEndDate(null);
    } else {
      const [startStr, endStr] = filterValue.split('_');
      const parsedStartDate = startStr ? parseDate(startStr) : null;
      const parsedEndDate = endStr ? parseDate(endStr) : null;

      setStartDate(parsedStartDate);
      setEndDate(parsedEndDate);
    }

    setTimeout(() => {
      isUpdatingFromUrl.current = false;
    }, 0);
  }, [id, searchParams]);

  return (
    <Box>
      {label && <div>{label}</div>}
      <Box display="flex" gap={2}>
        <DateInput
          label="Von"
          maxDate={endDate || undefined}
          value={startDate ? formatDate(startDate) : ''}
          onDateChange={handleStartDateChange}
        />
        <DateInput
          label="Bis"
          minDate={startDate || undefined}
          onDateChange={handleEndDateChange}
          value={endDate ? formatDate(endDate) : ''}
        />
      </Box>
    </Box>
  );
};
