import { FC } from 'react';
import { ExpandedActionsProps } from './ExpandedActions.d';
import { StyledMenu, StyledMenuItem } from './ExpandedActions.styled';
import { ReactComponent as CompareIcon } from 'assets/Icons/add-to-compare.svg';
import { ReactComponent as DownloladIcon } from 'assets/Icons/download.svg';
import { COLORS } from 'theme/colors';
import { ReactComponent as AddToFundIcon } from 'assets/Icons/add-fund.svg';
import { ReactComponent as RemoveFromFundIcon } from 'assets/Icons/remove-from-fund-list.svg';
import { ReactComponent as FeedbackIcon } from 'assets/Icons/feedback.svg';
import { ReactComponent as RefreshIcon } from 'assets/Icons/refresh-filled.svg';
import { ReactComponent as MessageIcon } from 'assets/Icons/messages.svg';
import { ReactComponent as AnalysisIcon } from 'assets/Icons/favorite-chart-filled.svg';
import { useProfile } from 'context/Profile';
import { MAX_COMPARIOSON_LIST_SIZE } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';

export const ExpandedActions: FC<ExpandedActionsProps> = ({
  open,
  menuWidth,
  isInMyFundList = false,
  isInComparisonList = false,
  isInMyPortfolio = false,
  anchorEl,
  requestReportText,
  setAnchorEl,
  onRequestReportHandler,
  onChangeFundListHandle,
  onChangeComparisonListHandle,
  onFeedbackHandle,
  onFundUpdateHandle,
  onRequestFundUpdateHandle,
  onPlansecurCommentsHandle,
  onFundPortraitHandle,
  onRequestCompetitiveAnalysisHandle,
  onOtherRequestHandle,
  onChangePortfolioHandle,
}) => {
  const profile = useProfile();
  const comparisonListSize = profile?.profileInfo?.comparison_list_size || 0;

  const allowToAddToComparisonList =
    comparisonListSize < MAX_COMPARIOSON_LIST_SIZE;
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      id="actions-menu"
      anchorEl={anchorEl}
      open={open}
      width={menuWidth}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {onRequestReportHandler && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestReportHandler(e);
          }}
          disableRipple
        >
          <DownloladIcon fill={COLORS.typography.description} />
          {requestReportText || 'FondsConsult Fondsporträt anfordern'}
        </StyledMenuItem>
      )}
      {onFeedbackHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onFeedbackHandle(e);
          }}
          disableRipple
        >
          <FeedbackIcon fill={COLORS.typography.description} />
          Feedback geben
        </StyledMenuItem>
      )}
      {onChangeFundListHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onChangeFundListHandle(e);
          }}
          disableRipple
        >
          {isInMyFundList ? (
            <RemoveFromFundIcon fill={COLORS.error.background} />
          ) : (
            <AddToFundIcon fill={COLORS.typography.description} />
          )}
          {isInMyFundList
            ? 'Aus meiner Fondsliste entfernen'
            : 'Zu Meine Fondsliste hinzufügen'}
        </StyledMenuItem>
      )}
      {onChangeComparisonListHandle && (
        <StyledMenuItem
          onClick={(e) => {
            e.preventDefault();
            handleClose(e);
            onChangeComparisonListHandle(e);
          }}
          disableRipple
          isWarning={isInComparisonList}
          disabled={!isInComparisonList && !allowToAddToComparisonList}
        >
          <CompareIcon
            fill={
              isInComparisonList
                ? COLORS.error.background
                : COLORS.typography.description
            }
          />
          {isInComparisonList
            ? 'Aus meiner Vergleichsliste entfernen'
            : 'Zu Meiner Vergleichsliste hinzufügen'}
          {!isInComparisonList &&
            !allowToAddToComparisonList &&
            '. (Sie haben bereits 5 Fonds in Ihrem Mein Fondsvergleich.)'}
        </StyledMenuItem>
      )}
      {onChangePortfolioHandle && (
        <StyledMenuItem
          isWarningOnHover={isInMyPortfolio}
          onClick={(e) => {
            handleClose(e);
            onChangePortfolioHandle(e);
          }}
          disableRipple
        >
          {isInMyPortfolio ? (
            <RemoveFromFundIcon fill={COLORS.error.background} />
          ) : (
            <AddToFundIcon fill={COLORS.typography.description} />
          )}
          {isInMyFundList
            ? 'Aus Portfolio entfernen'
            : 'Zum Portfolio hinzufügen'}
        </StyledMenuItem>
      )}
      {onFundPortraitHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onFundPortraitHandle(e);
          }}
          disableRipple
        >
          <DownloladIcon fill={COLORS.typography.description} />
          Fondsporträt herunterladen
        </StyledMenuItem>
      )}
      {onFundUpdateHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onFundUpdateHandle(e);
          }}
          disableRipple
        >
          <FeedbackIcon fill={COLORS.typography.description} />
          Fondsnews
        </StyledMenuItem>
      )}
      {onRequestFundUpdateHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestFundUpdateHandle(e);
          }}
          disableRipple
        >
          <RefreshIcon fill={COLORS.typography.description} />
          Fondsupdate anfordern
        </StyledMenuItem>
      )}
      {onRequestCompetitiveAnalysisHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onRequestCompetitiveAnalysisHandle(e);
          }}
          disableRipple
        >
          <AnalysisIcon fill={COLORS.typography.description} />
          Wettbewerbsanalyse anfordern
        </StyledMenuItem>
      )}
      {onPlansecurCommentsHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onPlansecurCommentsHandle(e);
          }}
          disableRipple
        >
          <MessageIcon fill={COLORS.typography.description} />
          Plansecur Kommentare
        </StyledMenuItem>
      )}
      {onOtherRequestHandle && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onOtherRequestHandle(e);
          }}
          disableRipple
        >
          <FeedbackIcon fill={COLORS.typography.description} />
          Sonstige Anfragen/Anmerkungen
        </StyledMenuItem>
      )}
    </StyledMenu>
  );
};
