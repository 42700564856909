import { format, isValid, parse, parseISO } from 'date-fns';
import { COLORS } from 'theme/colors';

export const pxToRem = (px: number) => `${px / 16}rem`;

export const debounce = (callback: (...args: any[]) => void, ms = 1500) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  return (...args: any[]) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      callback(...args);
    }, ms);
  };
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const formatCurrency = (
  value: number,
  currency: string = 'EUR',
): string => {
  const formattedValue = value.toLocaleString().replace(/,/g, ' ');
  return `${formattedValue} ${currency}`;
};

export const formatNumber = (value: number): string => {
  return value.toLocaleString().replace(/,/g, ' ');
};

export const convertToMillions = (value: number): number | string => {
  if (Math.abs(value) < 1_000_000) {
    return 'weniger als eine Mio EUR';
  }
  const millions = value / 1_000_000;
  const formatted = millions.toFixed(2);
  let result = formatted.replace('.', ',');

  result = result.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return result;
};

export const formatNumberWithComma = (
  value: number | null | undefined,
): string =>
  value === null || value === undefined
    ? '-'
    : value.toFixed(2).replace('.', ',');

export const formatNumberWithDots = (num: number | null): string => {
  if (num === null) {
    return '-';
  }
  const [integerPart, decimalPart] = num.toFixed(2).split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (decimalPart === '00') {
    return formattedInteger;
  }

  return `${formattedInteger},${decimalPart}`;
};

export const convertToPercentage = (value: number): string =>
  `${Math.round(value * 100)}%`;

export const getScoreColorByPercentage = (
  percentage: number,
): { main: string; secondary: string } => {
  if (percentage >= 0 && percentage <= 40) {
    return {
      main: COLORS.error.background,
      secondary: COLORS.error.hover,
    };
  } else if (percentage > 40 && percentage <= 60) {
    return {
      main: COLORS.typography.description,
      secondary: COLORS.background.module,
    };
  }
  return {
    main: COLORS.success.progress,
    secondary: COLORS.success.background,
  };
};

export const addDotsToString = (string: string, limit: number): string =>
  string?.length > limit ? `${string.substring(0, limit)}...` : string;

export const toTitleCase = (str: string): string => {
  if (!str) return '';

  return str
    .trim()
    .split(' ')
    .map((word) => {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export const capitalizeWords = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const createQueryParams = (params: {
  [key: string]: string | string[] | undefined;
}): string => {
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ''
    ) {
      if (Array.isArray(params[key])) {
        (params[key] as string[]).forEach((value) => {
          queryParams.append(key, value.toLowerCase());
        });
      } else {
        queryParams.append(key, (params[key] as string).toLowerCase());
      }
    }
  }
  return queryParams.toString();
};

export const parseDate = (dateString: string) => {
  const formats = ['dd.MM.yyyy', 'yyyy-MM-dd'];
  for (const dateFormat of formats) {
    const parsedDate = parse(dateString, dateFormat, new Date());
    if (isValid(parsedDate)) {
      return format(parsedDate, 'dd.MM.yyyy');
    }
  }

  try {
    const parsedDate = parseISO(dateString);
    if (isValid(parsedDate)) {
      return format(parsedDate, 'dd.MM.yyyy');
    }
  } catch (error) {
    console.log(`Error parsing ISO date: ${error}`);
  }

  throw new Error('Invalid date format');
};

export const parseDateTime = (dateTimeString: string) => {
  const date = new Date(dateTimeString);

  const pad = (num: number) => num.toString().padStart(2, '0');

  const formattedDate = `${pad(date.getDate())}.${pad(
    date.getMonth() + 1,
  )}.${date.getFullYear()}`;
  const formattedTime = `${pad(date.getHours())}:${pad(
    date.getMinutes(),
  )}:${pad(date.getSeconds())}`;

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const formatGermanDateTime = (timestamp: string): string => {
  try {
    if (!timestamp) return '-';

    const [datePart, timePart] = timestamp.split(' ');
    const [day, month, year] = datePart.split('.').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);

    const date = new Date(year, month - 1, day, hours, minutes);
    if (isNaN(date.getTime())) return '-';

    const formattedDay = date.getDate().toString().padStart(2, '0');
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedYear = date.getFullYear();
    const formattedHours = date.getHours().toString().padStart(2, '0');
    const formattedMinutes = date.getMinutes().toString().padStart(2, '0');

    return `am ${formattedDay}.${formattedMonth}.${formattedYear} um ${formattedHours}.${formattedMinutes} Uhr`;
  } catch (error) {
    console.error('Date formatting error:', error);
    return '-';
  }
};

export const customEncodeURIComponent = (str: string) => {
  return encodeURIComponent(str).replace(/\+/g, '%2B');
};

export const convertDateFormat = (dateStr: string): string => {
  if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
    return dateStr;
  }

  const [year, month, day] = dateStr.split('-');

  return `${day}.${month}.${year}`;
};
