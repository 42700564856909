import { PlansecurFilterChoicesProps } from 'types/Plansecur';
import {
  activeManagementOptions,
  rangeOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  volumeOptions,
} from '../Tables/FCSmartFundBenchmarkingTable/config';
import { PlansecurFiltersProps } from './CustomerOverview';
import {
  andereOptions,
  assetClassOptions,
  perfomanceRangeOptions,
  quartilRangeOptions,
  scopeRatingOptions,
  sharpeRatioRangeOptions,
  yearsOptions,
} from './options.config';

export const createPlansecurFilters = (
  dynamicOptions: PlansecurFilterChoicesProps = {
    plansecur_asset_class: [],
    regional_focus: [],
    company: [],
    morningstar_medalist_rating: [],
    scope_rating: [],
    appropriation_of_earnings: [],
    suitability_assessment_fund_category: [],
    fng_label: [],
    eet_template_version: [],
    esg_label_or_standard: [],
    sustainability_preference_sfdr: [],
  },
): PlansecurFiltersProps[] => [
  {
    categoryName: '',
    data: [
      {
        innerCategoryName: 'Anlageklasse',
        id: 'asset_class_plansecur',
        options: dynamicOptions.plansecur_asset_class
          ? dynamicOptions.plansecur_asset_class
              .filter((item): item is string => item !== null)
              .map((item) => ({
                value: item,
                name: item,
              }))
          : [],
        type: 'checkboxes',
      },
    ],
  },
  {
    categoryName: '',
    data: [
      {
        innerCategoryName: 'Peer-Gruppe',
        id: 'peer_group',
        options: [],
        type: 'search',
      },
    ],
  },
  {
    categoryName: '',
    data: [
      {
        innerCategoryName: 'Regionenschwerpunkt',
        id: 'regional_focus',
        options: dynamicOptions.regional_focus
          ? dynamicOptions.regional_focus
              .filter((item): item is string => item !== null)
              .map((item) => ({
                value: item,
                name: item,
              }))
          : [],
        type: 'multi-select',
      },
    ],
  },
  {
    categoryName: '',
    data: [
      {
        innerCategoryName: 'Auflage',
        id: 'launch_date',
        options: yearsOptions,
        type: 'date-range',
      },
    ],
  },
  {
    categoryName: '',
    data: [
      {
        innerCategoryName: 'Fondsvolumen',
        id: 'volume',
        options: volumeOptions,
        type: 'chips',
      },
    ],
  },
  {
    categoryName: 'Rendite',
    data: [
      {
        innerCategoryName: 'Rendite 1 Monat',
        id: 'performance_1_month',
        options: perfomanceRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Rendite 3 Monate',
        id: 'performance_3_months',
        options: perfomanceRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Rendite 6 Monate',
        id: 'performance_6_months',
        options: perfomanceRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Rendite 1 Jahr',
        id: 'performance_1_year',
        options: perfomanceRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Rendite 3 Jahre p.a.',
        id: 'performance_3_years_pa',
        options: perfomanceRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Rendite 5 Jahre p.a.',
        id: 'performance_5_years_pa',
        options: perfomanceRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Rendite 10 Jahre p.a.',
        id: 'performance_10_years_pa',
        options: perfomanceRangeOptions,
        type: 'range',
      },
    ],
  },
  {
    categoryName: 'Volatilität',
    data: [
      {
        innerCategoryName: 'Volatilität 1 Jahr',
        id: 'volatility_1_year',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Volatilität 3 Jahre',
        id: 'volatility_3_years',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Volatilität 5 Jahre',
        id: 'volatility_5_years',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Volatilität 10 Jahre',
        id: 'volatility_10_years',
        options: rangeOptions,
        type: 'range',
      },
    ],
  },
  {
    categoryName: 'Sharpe Ratio',
    data: [
      {
        innerCategoryName: 'Sharpe Ratio 1 Jahr',
        id: 'sharpe_ratio_1_year',
        options: sharpeRatioRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Sharpe Ratio 3 Jahre',
        id: 'sharpe_ratio_3_years',
        options: sharpeRatioRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Sharpe Ratio 5 Jahre',
        id: 'sharpe_ratio_5_years',
        options: sharpeRatioRangeOptions,
        type: 'range',
      },
    ],
  },
  {
    categoryName: 'Externe Ratings',
    data: [
      {
        innerCategoryName: 'FWW FundStars(R)',
        id: 'fww_fundstars_rating_min',
        options: [],
        type: 'star-rating',
      },
      {
        innerCategoryName: 'Morningstar Rating(TM)',
        id: 'morningstar_medalist_rating',
        options: dynamicOptions.morningstar_medalist_rating
          ? dynamicOptions.morningstar_medalist_rating
              .filter((item): item is string => item !== null)
              .map((item) => ({
                value: item,
                name: item,
              }))
          : [],
        type: 'chips',
      },
      {
        innerCategoryName: 'Scope Mutual Fund Rating',
        id: 'scope_rating',
        options: dynamicOptions.scope_rating
          ? dynamicOptions.scope_rating
              .filter((item): item is string => item !== null && item !== '-')
              .map((item) => ({
                value: item,
                name: item,
              }))
          : [],
        type: 'chips-rating',
      },
    ],
  },
  {
    categoryName: 'FondsConsult Smart Fund Benchmarking',
    data: [
      {
        innerCategoryName: 'Quartil',
        id: 'quartil',
        options: quartilRangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Track Record',
        id: 'track_record',
        options: trackRecordOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Rendite',
        id: 'return_score',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Risiko',
        id: 'risk',
        options: riskOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Aktives Management',
        id: 'active_management_score',
        options: activeManagementOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'ESG Credibility Score',
        id: 'credibility',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'ESG Action (Fonds)',
        id: 'action_score_fund',
        options: rangeOptions,
        type: 'range',
      },
    ],
  },
  {
    categoryName: 'Preis und Kosten',
    data: [
      {
        innerCategoryName: 'Managementgebühr in %',
        id: 'management_fee_percentage',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Performancegebühr in %',
        id: 'performance_fee_percentage',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Representative Cost (ex Transaction) in %',
        id: 'representative_cost_ex_transaction_percentage',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'Representative Cost (inc Transaction) in %',
        id: 'representative_cost_inc_transaction_percentage',
        options: rangeOptions,
        type: 'range',
      },
    ],
  },
  {
    categoryName: '',
    data: [
      {
        innerCategoryName: 'Andere',
        id: 'others',
        options: andereOptions,
        type: 'checkboxes-others',
      },
    ],
  },
];

export const plansecurFilters = createPlansecurFilters();
