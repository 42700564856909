import { forwardRef } from 'react';
import {
  CommentFormProps,
  ConfirmWithCommentModalProps,
} from './ConfirmWithCommentModal.d';
import { Modal } from 'components/common/Modal/Modal';
import { StyledContainer, StyledIsin } from './ConfirmWithCommentModal.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Textarea } from 'components/common/Textarea';
import { useForm } from 'react-hook-form';
import { commentFormValidationSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { raiseToast } from 'components/common/Toast/raiseToast';
import {
  fetchAddRemoveFundToCompassStatus,
  sendOtherFundRequest,
} from 'services/Plansecur';
import { Toast } from 'components/common/Toast';

export const ConfirmWithCommentModal = forwardRef<
  HTMLDivElement,
  ConfirmWithCommentModalProps
>(
  (
    {
      isShown,
      variant,
      toggle,
      title,
      subtitle,
      fundName,
      isin,
      fundId,
      isWarning = false,
      confirmButtonText = 'Ja',
      cancelButtonText = 'Abbrechen',
      handleSubmitSend,
    },
    ref,
  ) => {
    const isSendRequestToAddToKompass =
      variant === 'send-request-to-add-to-kompass';
    const { handleSubmit, register, reset } = useForm<CommentFormProps>({
      resolver: yupResolver(commentFormValidationSchema),
      mode: 'onSubmit',
    });

    const updateCompassStatus = async (comment: string) => {
      const response = await fetchAddRemoveFundToCompassStatus({
        fund: fundId,
        notes: comment,
        type: isSendRequestToAddToKompass ? 'Addition' : 'Removal',
      });
      if (response.ok) {
        isSendRequestToAddToKompass
          ? raiseToast.requestToAddToKompass(
              <Toast
                title="In Kompass-Entwurf aufnehmen"
                content={`Die Anfrage, den Fonds "${fundName}" zum Kompass-Entwurf hinzuzufügen, wurde erfolgreich gesendet.`}
              />,
            )
          : raiseToast.requestToRemoveFromKompass(
              <Toast
                title="Aus Kompass-Entwurf"
                content={`Die Anfrage, den Fonds "${fundName}" aus dem Kompass-Entwurf zu entfernen, wurde erfolgreich gesendet.`}
              />,
            );
        reset();
        toggle();
        handleSubmitSend();
      }
    };

    const sendOtherRequest = async (comment: string) => {
      const response = await sendOtherFundRequest(fundId, comment);
      if (response.ok) {
        raiseToast.send(
          'Sonstige Anfragen/Anmerkungen wurden erfolgreich gesendet.',
        );
        reset();
        toggle();
        handleSubmitSend();
      }
    };

    const onSubmit = async (formData: CommentFormProps) => {
      switch (variant) {
        case 'send-request-to-add-to-kompass':
          updateCompassStatus(formData.comment);
          break;
        case 'send-request-to-remove-from-kompass':
          updateCompassStatus(formData.comment);
          break;
        case 'send-other-request':
          sendOtherRequest(formData.comment);
          break;
        default:
          break;
      }
    };

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        title={title}
        px={4}
        pb={5}
        pt={4}
      >
        <StyledContainer pt={2.5}>
          {!!subtitle?.length && (
            <Typography variant="body" color={COLORS.typography.description}>
              {subtitle}
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            pt={!!subtitle ? 5 : 2}
            pb={2.5}
          >
            <Typography variant="h5">{fundName}</Typography>
            <StyledIsin variant="body">{isin}</StyledIsin>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Textarea
              placeholder="Dein Kommentar..."
              {...register('comment')}
            />
            <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
              <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
                {cancelButtonText}
              </Button>
              <Button
                variant={
                  isWarning ? ButtonVariant.WARNING : ButtonVariant.PRIMARY
                }
                type="submit"
              >
                {confirmButtonText}
              </Button>
            </Box>
          </form>
        </StyledContainer>
      </Modal>
    );
  },
);
