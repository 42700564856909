import { ColumnProps } from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { CheckStatusIndicator } from 'components/common/Icons/CheckStatusIndicator';
import { Option } from 'components/common/Select/Select.d';
import { Box } from 'components/common/Box';
import { TableChip } from 'components/common/TableChip/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { NameColumn } from 'components/Modules/NameColumn';
import { StarRatingsColumn } from 'components/Modules/StarRatingsColumn';
import { morningstarMedalistRatingOptions } from '../options';
import { StyledTableChipContainer } from '../CustomerOverview.styled';
import { PurchasePriceIndicator } from 'components/Modules/PurchasePriceIndicator';
import { Score } from 'components/Modules/Score';
import {
  activeManagementOptions,
  returnOptions,
  riskOptions,
  trackRecordOptions,
  volumeOptions,
} from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';
import { Rank } from 'components/Modules/Rank';
import { quantileGradeColors } from './options.config';

export const getDefaultFilterValue = (value: string, options: Option[]) => {
  const [result] = options.filter((option) => option.value === value);
  return result;
};

export const statusOptions: Option[] = [
  {
    value: 'In Kompass aufnehmen',
    name: 'In Kompass aufnehmen',
    chipVariant: TableChipVariant.GREEN,
  },
  {
    value: 'Aus Kompass streichen',
    name: 'Aus Kompass streichen',
    chipVariant: TableChipVariant.RED,
  },
];

export const commonColumns: ColumnProps[] = [
  {
    id: 'name',
    label: 'Name',
    tooltip: 'The name of the item',
    sortable: true,
    renderCell: ({ row, value }) => {
      return <NameColumn variant="small" name={value} type={row.isin} />;
    },
    subColumns: [],
  },
  {
    id: 'fonds_consult_daten',
    label: 'FondsConsult Daten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'sfb_related_fund__peer_group',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'FCR Peergroup',
        sortable: true,
        minWidth: '148px',
        maxWidth: '148px',
      },
      {
        id: 'sfb_related_fund__rank__rank',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rang',
        sortable: true,
        sortKey: 'sfb_related_fund__rank__rank',
        tooltip:
          'Im Rahmen unserer proprietären Quant-Auswertung erzielter Rang des Fonds innerhalb seiner Vergleichsgruppe. Die Platzierung basiert auf diversen Rendite-/Risikokennzahlen über einen Zeitraum von einem bzw. drei Jahren.',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ row }) => (
          <Rank
            value={row.sfb_related_fund__rank__rank}
            maxValue={row.nr_of_funds}
          />
        ),
      },
      {
        id: 'quartil',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quartil',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <LetterBadge value={value} />,
      },
      {
        id: 'sfb_related_fund__volume',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondsvolumen',
        sortable: true,
        tooltip:
          'Fondsvolumen eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Hoch (Top 30%), Mittel, Niedrig (Untere 30%).',
        minWidth: '156px',
        maxWidth: '156px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, volumeOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__track_record',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Track Record',
        sortable: true,
        tooltip:
          'Laufzeit eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Lang (Top 30%), Mittel, Kurz (Untere 30%).',
        minWidth: '115px',
        maxWidth: '115px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, trackRecordOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__return_score__total_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rendite',
        sortable: true,
        tooltip:
          'Historisch erzielte Rendite des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, returnOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_risk_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Risiko',
        sortable: true,
        tooltip:
          'Gemessen über die Volatilität, den max. Verlust, das Beta sowie die eigens entwickelte Downside Protection des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht, Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(value, riskOptions);
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__total_active_management_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Aktives Management',
        sortable: true,
        tooltip:
          'Gemessen über das zur Benchmark erzielte Alpha, sowie die Information und Appraisal Ratio des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            activeManagementOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'sfb_related_fund__esg_scores__credibility_score',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Credibility',
        sortable: true,
        tooltip:
          'Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen wird.',
        minWidth: '127px',
        maxWidth: '127px',
        renderCell: ({ value }) => <Score value={value} />,
      },
      {
        id: 'esg_actions',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Actions (Fonds)',
        tooltip:
          'Bewertung der auf Fondsebene tatsächlich umgesetzten Nachhaltigkeitskriterien (u.a. PAIs). Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Berücksichtigung nachhaltiger Kriterien gesprochen wird. Mehr Informationen dazu finden Sie im Bereich ESG Credibility & Action Score.',
        sortable: true,
        minWidth: '127px',
        maxWidth: '127px',
      },
    ],
  },
  {
    id: 'stammdaten',
    label: 'Stammdaten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'wkn',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'WKN',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondswährung',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
      },
      {
        id: 'company',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Gesellschaft',
        sortable: true,
        minWidth: '160px',
        maxWidth: '160px',
      },
      {
        id: 'category',
        headAlign: 'left',
        cellAlign: 'left',
        label: 'Fondskategorie / Asset-Schwerpunkt',
        sortable: true,
        minWidth: '169px',
        maxWidth: '169px',
      },
      {
        id: 'regional_focus',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Regionenschwerpunkt',
        sortable: true,
        minWidth: '177px',
        maxWidth: '177px',
      },
      {
        id: 'currency_focus',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Währungsschwerpunkt',
        sortable: true,
        minWidth: '182px',
        maxWidth: '182px',
      },
      {
        id: 'plansecur_asset_class',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Plansecur-Assetklasse',
        sortable: true,
        minWidth: '182px',
        maxWidth: '182px',
      },
      {
        id: 'launch_date',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Auflegung',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'preis',
    label: 'Preis und Kosten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'aa_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'AA in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'management_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Managementgebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Performancegebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'representative_cost_ex_transaction_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Representative Cost (ex Transaction) in %',
        sortable: true,
        minWidth: '170px',
        maxWidth: '170px',
      },
      {
        id: 'representative_cost_inc_transaction_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Representative Cost (inc Transaction) in %',
        sortable: true,
        minWidth: '170px',
        maxWidth: '170px',
      },
      {
        id: 'issue_price',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausgabepreis',
        sortable: true,
        minWidth: '118px',
        maxWidth: '118px',
      },
      {
        id: 'redemption_price',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rücknahmepreis',
        sortable: true,
        minWidth: '138px',
        maxWidth: '138px',
      },
      {
        id: 'price_currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preiswährung',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'price_date',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preisdatum',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'rendite',
    label: 'Rendite',
    visibleSubColumnsNumber: 4,
    subColumns: [
      {
        id: 'performance_current_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Laufendes Jahr',
        sortable: true,
        minWidth: '130px',
        maxWidth: '130px',
      },
      {
        id: 'performance_1_month',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Monat',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Monate',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_6_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: '6 Monate',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_10_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_since_edition',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Seit Auflage',
        sortable: true,
        minWidth: '108px',
        maxWidth: '108px',
      },
      {
        id: 'performance_3_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_5_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_10_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre p.a.',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'performance_since_edition_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Seit Auflage p.a.',
        sortable: true,
        minWidth: '137px',
        maxWidth: '137px',
      },
      {
        id: 'calendar_year_2020',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2020',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'quintile_2020',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2020',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'calendar_year_2021',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2021',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'quintile_2021',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2021',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'calendar_year_2022',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2022',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'quintile_2022',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2022',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'calendar_year_2023',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2023',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'quintile_2023',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2023',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'calendar_year_2024',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2024',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'quintile_2024',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil 2024',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
        renderCell: ({ value }) => (
          <LetterBadge value={value} gradeColors={quantileGradeColors} />
        ),
      },
      {
        id: 'quintile_sum',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Quintil-Summe',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
    ],
  },
  {
    id: 'aktives_anagement',
    label: 'Aktives Management',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'sharpe_ratio_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 1 Jahr',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'sharpe_ratio_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 3 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'sharpe_ratio_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 5 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'treynor_ratio',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Treynor Ratio',
        sortable: true,
        minWidth: '116px',
        maxWidth: '116px',
      },
      {
        id: 'jensen_alpha',
        headAlign: 'center',
        cellAlign: 'center',
        label: "Jensen's Alpha",
        sortable: true,
        minWidth: '130px',
        maxWidth: '130px',
      },
    ],
  },
  {
    id: 'volatility',
    label: 'Volatilität',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'volatility_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_10_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'information_ratio_data',
    label: '',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'information_ratio',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Information Ratio',
        sortable: true,
        minWidth: '147px',
        maxWidth: '147px',
      },
    ],
  },
  {
    id: 'externe_ratings',
    label: 'Externe Ratings',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'fww_fundstars_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FWW FundStars(R)',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
        renderCell: ({ value }) => <StarRatingsColumn value={value} />,
      },
      {
        id: 'morningstar_medalist_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Morningstar Medalist Rating',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            morningstarMedalistRatingOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'scope_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Scope Mutual Fund Rating',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <LetterBadge value={value} />,
      },
    ],
  },
  {
    id: 'abwicklung',
    label: 'Fondsstruktur & Abwicklung',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'appropriation_of_earnings',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ertragsverwendung',
        sortable: true,
        minWidth: '158px',
        maxWidth: '158px',
      },
      {
        id: 'index_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Indexfonds',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'vl_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'VL-Fonds',
        sortable: true,
        minWidth: '90px',
        maxWidth: '90px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'ffb_online_order_cutoff_time',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FFB-Online\n -Orderschlusszeit',
        sortable: true,
        minWidth: '150px',
        maxWidth: '150px',
      },
      {
        id: 'price_fixing_purchase_offset',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preisfestste -llung Kauf',
        sortable: true,
        minWidth: '106px',
        maxWidth: '106px',
        renderCell: ({ value }) => <PurchasePriceIndicator value={value} />,
      },
      {
        id: 'custody_fee',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verwahrentgelt',
        sortable: true,
        minWidth: '129px',
        maxWidth: '129px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'portfolio_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Portfoliofähig',
        sortable: true,
        minWidth: '117px',
        maxWidth: '117px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'savings_plan_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sparplanfähig',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'buy',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'sell',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verkauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'payment_plan',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Auszahlplan',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'fund_of_funds',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Dachfonds',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'volume_in_million_eur',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Volumen umgerechnet\n in Mio. EUR',
        sortable: true,
        minWidth: '178px',
        maxWidth: '178px',
      },
      {
        id: 'suitability_assessment_fund_category',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Angemessenheitsbeurteilung - Fondskategorie',
        sortable: true,
        minWidth: '225px',
        maxWidth: '225px',
      },
    ],
  },
  {
    id: 'nachhaltigkeit',
    label: 'Nachhaltigkeit',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'fng_label',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FNG-Siegel',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_star_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sterne',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'eet_template_version',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'EET Template Version',
        sortable: true,
        minWidth: '120px',
        maxWidth: '120px',
      },
      {
        id: 'esg_label_or_standard',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Label or Standard',
        sortable: true,
        minWidth: '110px',
        maxWidth: '110px',
      },
      {
        id: 'pornography_exclusion',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Exclusion PorNeingraphie',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'exclusion_threshold',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Exclusion Threshold',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_preference_sfdr',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'NH-Präferenz gem. SFDR',
        sortable: true,
        minWidth: '120px',
        maxWidth: '120px',
      },
      {
        id: 'ungc_oecd_violations_considered',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'UNGC Principles Or OECD Guidelines Violations Considered',
        sortable: true,
        minWidth: '250px',
        maxWidth: '250px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
    ],
  },
];
