import styled from 'styled-components';
import BackgroundHelpBoxImage from 'assets/Backgrounds/analyst-desk-helper-box.jpeg';
import { Box } from '../Box';
import { SIZES } from 'theme/sizes';
import { COLORS } from 'theme/colors';
import { Button } from '../Buttons/Button';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';
import { StyledItemProps } from './HelpBox.d';

export const HelpBoxWrapper = styled(Box)<StyledItemProps>`
  max-height: calc(100vh - 65px);
  border-radius: ${({ isSmallVariant }) => (isSmallVariant ? '16' : '8')}px;
  background-image: url(${BackgroundHelpBoxImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${SIZES.media.tablet} {
    max-height: calc(100vh - 200px);
  }
`;

export const StyledLeftContainer = styled(Box)`
  width: 55%;
`;

export const StyledAnalystDeskButton = styled(Button)`
  &:enabled {
    background-color: ${COLORS.background.secondary};
    color: ${COLORS.accent.primary};
  }

  svg {
    stroke: ${COLORS.accent.primary};
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${COLORS.accent.primary};
  width: 18px;
  height: 18px;
  transform: rotate(135deg);
  margin-right: 3px;
  margin-top: 3px;
`;
