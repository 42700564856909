import { forwardRef, useEffect } from 'react';
import {
  CommentFormProps,
  MaintainAdditionalInfoModalProps,
} from './MaintainAdditionalInfoModal.d';
import { Modal } from 'components/common/Modal/Modal';
import {
  StyledContainer,
  StyledIsin,
} from './MaintainAdditionalInfoModal.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Textarea } from 'components/common/Textarea';
import { useForm } from 'react-hook-form';
import { commentFormValidationSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { fetchEditAdditionalInformation } from 'services/Plansecur';

export const MaintainAdditionalInfoModal = forwardRef<
  HTMLDivElement,
  MaintainAdditionalInfoModalProps
>(
  (
    {
      isShown,
      toggle,
      title,
      subtitle,
      fundName,
      isin,
      fundId,
      brokerNotes,
      investmentGoal,
      isWarning = false,
      confirmButtonText = 'Ja',
      cancelButtonText = 'Abbrechen',
      handleSubmitSend,
    },
    ref,
  ) => {
    const { handleSubmit, register, reset, setValue } =
      useForm<CommentFormProps>({
        resolver: yupResolver(commentFormValidationSchema),
        mode: 'onSubmit',
        defaultValues: {
          investment_goal: investmentGoal,
          broker_notes: brokerNotes,
        },
      });

    const onSubmit = async ({
      investment_goal,
      broker_notes,
    }: CommentFormProps) => {
      const response = await fetchEditAdditionalInformation({
        fundId,
        investment_goal,
        broker_notes,
      });
      if (response.ok) {
        raiseToast.send(
          `Die Zusatzinfos für den Fonds "${fundName}" wurden erfolgreich gepflegt.`,
        );
        reset();
        toggle();
        handleSubmitSend();
      }
    };

    useEffect(() => {
      investmentGoal && setValue('investment_goal', investmentGoal);
      brokerNotes && setValue('broker_notes', brokerNotes);
    }, [brokerNotes, investmentGoal]);

    useEffect(() => {
      if (!isShown) {
        reset();
      }
    }, [isShown]);

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        title={title}
        px={4}
        pb={5}
        pt={4}
      >
        <StyledContainer pt={2.5}>
          {!!subtitle?.length && (
            <Typography variant="body" color={COLORS.typography.description}>
              {subtitle}
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            pt={!!subtitle ? 5 : 2}
            pb={2.5}
          >
            <Typography variant="h5">{fundName}</Typography>
            <StyledIsin variant="body">{isin}</StyledIsin>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" gap={5}>
              <Textarea
                label="Anlageziel"
                placeholder="Ihr Kommentar ..."
                {...register('investment_goal')}
              />
              <Textarea
                label="Hinweis für Vermittler"
                placeholder="Ihr Kommentar ..."
                {...register('broker_notes')}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
              <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
                {cancelButtonText}
              </Button>
              <Button
                variant={
                  isWarning ? ButtonVariant.WARNING : ButtonVariant.PRIMARY
                }
                type="submit"
              >
                {confirmButtonText}
              </Button>
            </Box>
          </form>
        </StyledContainer>
      </Modal>
    );
  },
);
