import { PortfolioCollapseTable } from 'components/common/PortfolioCollapseTable';
import { FC, useEffect, useState } from 'react';
import {
  ColumnProps,
  RowProps,
  SortModelProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { Actions } from 'components/Modules/Actions';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ModuleLayout } from '../ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { createRow } from '../config';
import { useProfile } from 'context/Profile';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import {
  fetchAddFundsToWatchlist,
  fetchPlansecurFund,
  fetchUpdateFundPortfolioStatus,
  getPlansecurFundFilterChoices,
  PlansecurFundRequest,
} from 'services/Plansecur';
import { ConfirmModal } from 'components/common/Modals/ConfirmModal';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { COLORS } from 'theme/colors';
import { PlansecurFiltersModal } from 'components/Modules/Filters/PlansecurFiltersModal/PlansecurFiltersModal';
import { useNavigation } from 'context/NavigationProvider/NavigationProvider';
import { createPlansecurFilters, plansecurFilters } from '../filters.config';
import { PlansecurFiltersProps } from '../CustomerOverview.d';
import { FilterProps } from 'components/Modules/Filters/SelectedFilters/SelectedFilters.d';
import { convertDateFormat, toTitleCase } from 'utils/common';
import { Option } from 'components/common/Select/Select.d';
import { commonColumns } from './config';

const DAFAULT_PAGE_SIZE = 50;

export const FundSearch: FC = () => {
  const profile = useProfile();
  const { customNavigate } = useNavigation();
  const profileInfo = profile?.profileInfo;
  const assignedAssetClasses = profile?.profileInfo?.assigned_portfolio_pate
    ?.map((item) => item.assigned_asset_classes)
    .flat();
  const isPlansecurAdmin =
    profileInfo?.plansecur_admin_access &&
    profileInfo?.plansecur_fonds_pate_access;
  const isFondsPateUser =
    profileInfo?.plansecur_fonds_pate_access &&
    !profileInfo?.plansecur_admin_access;
  const isAdmin =
    profileInfo?.plansecur_fonds_pate_access &&
    profileInfo?.plansecur_admin_access;
  const { search, pathname } = useLocation();
  const [rows, setRows] = useState<RowProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [isPrefiltered, setIsPrefiltered] = useState(
    !!assignedAssetClasses?.length,
  );
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const query = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(
    query.get('page-size') || DAFAULT_PAGE_SIZE.toString(),
    10,
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [
    isConfirmRemoveFromPortfolioOpen,
    setIsConfirmRemoveFromPortfolioOpen,
  ] = useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    isin: '',
  });
  const [assetClassesOptions, setAssetClassesOptions] = useState<Option[]>(
    assignedAssetClasses
      ? [
          ...assignedAssetClasses.map((item) => ({
            value: item.id,
            name: item.name,
          })),
        ]
      : [],
  );
  const [selectedAssetClass, setSelectedAssetClass] = useState<Option | null>(
    assetClassesOptions[0] || null,
  );
  const [filtersConfig, setFiltersConfig] = useState(plansecurFilters);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const showConfirmRemoveFromPortfolioModal = (
    name: string,
    id: string,
    isin: string,
  ) => {
    setIsConfirmRemoveFromPortfolioOpen(true);
    setCurrentFund({ id, name, isin });
  };

  const changeFundPortfolioStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundPortfolioStatus(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromPortfolioOpen &&
        setIsConfirmRemoveFromPortfolioOpen(false);
      isConfirmRemoveFromPortfolioOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds wurde aus Ihrem Portfolio entfernt"
              content={`"${name}" wurde aus Ihrem Portfolio entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds wurde zu Ihrem Portfolio hinzugefügt"
              content={`"${name}" wurde zu Ihrem Portfolio hinzugefügt.`}
            />,
          );
    }
  };

  const onChangePortfolioHandle = (row: RowProps) => {
    row.is_watchlisted
      ? showConfirmRemoveFromPortfolioModal(row.name, row.id, row.isin)
      : changeFundPortfolioStatus(row.id, row.name);
  };

  const columns: ColumnProps[] = [
    ...commonColumns,
    ...(isFondsPateUser
      ? [
          {
            id: 'actions',
            label: 'Aktionen',
            tooltip: 'Aktionen',
            renderCell: ({ row }: { row: RowProps }) => (
              <Actions
                isInMyPortfolio={row.is_watchlisted}
                disabledToRemoveFromPortfolio={row.present_in_kompass}
                internalHandlers={{
                  onChangePortfolioHandle: isFondsPateUser
                    ? () => onChangePortfolioHandle(row)
                    : undefined,
                }}
                justifyContent="center"
                mr={0}
              />
            ),
            width: '58px',
            subColumns: [],
          },
        ]
      : []),
  ];

  const formatDataToSend = () => {
    const filters: PlansecurFundRequest = {};
    for (const filterCategory of plansecurFilters) {
      for (const filter of filterCategory.data) {
        if (!!filter.id) {
          const filterValues = searchParams.getAll(filter.id.toString());
          if (!!filterValues.length) {
            if (filter.type === 'range') {
              const [min, max] = filterValues[0].split('_');
              filters[`${String(filter.id)}_min`] = min;
              filters[`${String(filter.id)}_max`] = max;
            } else if (filter.type === 'date-range') {
              const [after, before] = filterValues[0].split('_');
              if (after && after.length > 0)
                filters[`${String(filter.id)}_after`] = after;
              if (before && before.length > 0)
                filters[`${String(filter.id)}_before`] = before;
            } else if (filter.type === 'chips-article') {
              filters[String(filter.id)] = filterValues.map(
                (item) => `Artikel ${item}`,
              );
            } else if (filter.type === 'chips-rating') {
              filters[String(filter.id)] = filterValues.map((item) =>
                item.toUpperCase(),
              );
            } else if (filter.type === 'checkboxes-others') {
              filterValues.forEach((item) => {
                filters[item] = 'true';
              });
            } else {
              filters[String(filter.id)] = filterValues;
            }
          }
        }
      }
    }
    return filters;
  };

  const getTableData = async () => {
    const filters = formatDataToSend();
    sortModel === null && setLoading(true);
    const data = {
      page,
      page_size: itemsPerPage,
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
      ...(isPrefiltered ? { filter_package: 'true' } : {}),
      ...(selectedAssetClass?.value !== 'All' && isPrefiltered
        ? { asset_class: selectedAssetClass?.value }
        : {}),
    };

    const response = await fetchPlansecurFund({ ...data, ...filters });

    if (response.ok) {
      const tableData = response.parsedBody.results.funds.map((item) => ({
        ...item,
        highlightColor: item.present_in_kompass
          ? COLORS.info.selectedBorder
          : isPrefiltered
          ? COLORS.warning.dark
          : COLORS.background.secondary,
        highlightTooltip: item.present_in_kompass
          ? 'Kompass-Fonds'
          : isPrefiltered
          ? 'Top Ranking-Fonds der Anlageklasse'
          : '',
      }));
      setResultsNumber(response.parsedBody.count);
      setRows(tableData.map((p: PlansecurFundProps) => createRow(p)));
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [
    itemsPerPage,
    searchValue,
    sortModel,
    searchParams,
    isPrefiltered,
    selectedAssetClass,
  ]);

  useEffect(() => {
    const loadFilterOptions = async () => {
      try {
        setIsLoadingFilters(true);
        const response = await getPlansecurFundFilterChoices();
        const options = response.parsedBody;
        setFiltersConfig(createPlansecurFilters(options));
      } catch (error) {
        console.error('Failed to load filter options:', error);
      } finally {
        setIsLoadingFilters(false);
      }
    };

    loadFilterOptions();
  }, []);

  const addMultipleFundsToPortfolio = async () => {
    const response = await fetchAddFundsToWatchlist(selectedRows);
    if (response.ok) {
      raiseToast.addToFundList(
        'Fonds, die zu Ihrer Watchlist hinzugefügt wurden.',
      );
      setSelectedRows([]);
      getTableData();
    }
  };

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => !row.present_in_kompass && !row.is_watchlisted);
  };

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toogleConfirmModal = () => {
    setIsConfirmRemoveFromPortfolioOpen(!isConfirmRemoveFromPortfolioOpen);
  };

  const handleSubmitConfirmModal = () => {
    if (isConfirmRemoveFromPortfolioOpen) {
      changeFundPortfolioStatus(currentFund.id, currentFund.name);
      return;
    }
  };

  const clearFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    customNavigate(`${pathname}${tabSearchParam ? `?${newSearchParams}` : ''}`);
  };

  const getFilters = (filtersOptions: PlansecurFiltersProps[]) => {
    const filters: ({
      value: string;
      name: string;
      category: string;
    } & FilterProps)[] = [];

    const addFilter = (
      value: string,
      name: string,
      label: string,
      id: string,
      categoryName: string,
    ) => {
      filters.push({
        value,
        name: toTitleCase(name) || '',
        label,
        id,
        category: categoryName,
      });
    };

    const processFilterValue = (
      filterValue: string[],
      id: string,
      type: string,
      innerCategoryName: string,
      options: any[],
      categoryName: string,
    ) => {
      if (type === 'search' || type === 'star-rating') {
        addFilter(
          filterValue[0],
          filterValue[0],
          innerCategoryName,
          id,
          categoryName,
        );
        return;
      }

      if (type === 'range') {
        if (filterValue && filterValue[0]) {
          const [min, max] = filterValue[0].split('_');
          const formatRange = (
            min?: string | null,
            max?: string | null,
          ): string => {
            if (min && max) return `${min} - ${max}`;
            if (min) return `${min}`;
            if (max) return `${max}`;
            return '';
          };
          addFilter(
            `${formatRange(min, max)}`,
            `${formatRange(min, max)}`,
            innerCategoryName,
            id,
            categoryName,
          );
        }
      }

      if (type === 'date-range') {
        if (filterValue && filterValue[0]) {
          const [min, max] = filterValue[0].split('_');
          const formatRange = (
            min?: string | null,
            max?: string | null,
          ): string => {
            if (min && max)
              return `${convertDateFormat(min)} - ${convertDateFormat(max)}`;
            if (min) return `${convertDateFormat(min)}`;
            if (max) return `${convertDateFormat(max)}`;
            return '';
          };
          addFilter(
            `${formatRange(min, max)}`,
            `${formatRange(min, max)}`,
            innerCategoryName,
            id,
            categoryName,
          );
        }
      }

      const result = options.filter((option) =>
        filterValue.includes(option.value.toLowerCase()),
      );
      result.forEach((item) => {
        const value = item?.value;
        const name =
          type === 'chips-article' ? `Artikel ${item?.name}` : item?.name;
        addFilter(value, name, innerCategoryName, id, categoryName);
      });
    };

    Object.values(filtersOptions).forEach((filter) =>
      Array.isArray(filter.data)
        ? filter.data.map(({ id, type, innerCategoryName, options }) => {
            const filterValue = searchParams.getAll(id.toString());
            if (filterValue) {
              processFilterValue(
                filterValue,
                id.toString(),
                type,
                innerCategoryName,
                options,
                filter.categoryName,
              );
            }
          })
        : null,
    );

    return filters;
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrefiltered(event.target.checked);
  };

  return (
    <>
      <ConfirmModal
        isWarning={isConfirmRemoveFromPortfolioOpen}
        title={'Aus dem Portfolio entfernen'}
        subtitle={`Sind Sie sicher, dass Sie ${currentFund?.name} aus dem Portfolio entfernen möchten?`}
        isShown={isConfirmRemoveFromPortfolioOpen}
        toggle={toogleConfirmModal}
        onSubmit={handleSubmitConfirmModal}
        confirmButtonText="Ja, Entfernen"
      />
      <PlansecurFiltersModal
        isShow={openFilters}
        closeModal={() => setOpenFilters(false)}
        clearFilters={clearFilters}
        filters={filtersConfig}
        selectedFilters={getFilters(filtersConfig).filter(
          (item) => !!item.value,
        )}
        isLoading={isLoadingFilters}
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={0}
        switchChecked={isPrefiltered}
        switchLabel="Nur Ranking-Fonds"
        portfoliosOptions={isPrefiltered ? assetClassesOptions : undefined}
        selectedPortfolio={selectedAssetClass}
        setSelectedPortfolio={setSelectedAssetClass}
        onSwitchChange={handleSwitchChange}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
        onExportClick={() => getTableData()}
      >
        <SelectedFilters
          filters={getFilters(filtersConfig)}
          clearFilters={clearFilters}
        />
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedRows.length !== 0 && (
            <SelectedRow
              variant="standart"
              selectedItemsNumber={selectedRows.length}
              handleCancelSelectingRows={() => setSelectedRows([])}
              handleAddToPortfolio={
                isFondsPateUser ? addMultipleFundsToPortfolio : undefined
              }
              isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
            />
          )}
          <PortfolioCollapseTable
            isLastColumnsFixed={isFondsPateUser}
            loading={loading}
            tableData={{
              rows,
              columns,
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </Box>
        <Pagination
          pagesNumber={getPagesNumber()}
          defaultPageSize={DAFAULT_PAGE_SIZE}
        />
      </ModuleLayout>
    </>
  );
};
