import {
  FormControlLabel,
  Switch,
  formControlLabelClasses,
  styled,
} from '@mui/material';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';

export const StyledSwitch = styled(Switch)(({ color }) => ({
  width: 52,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:
          color === 'secondary'
            ? COLORS.warning.dark
            : COLORS.info.selectedBorder,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: COLORS.info.selectedBorder,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'grey',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 28,
    height: 28,
    border: `0.5px solid ${hexToRGB(COLORS.typography.main, '0.04')}`,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: hexToRGB(COLORS.info.primary, '0.16'),
    opacity: 1,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)({
  color: COLORS.typography.description,
  marginRight: 0,
  [`& .${formControlLabelClasses.label}`]: {
    marginLeft: 10,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14px',
    fontFamily: '"Inter", sans-serif',
  },
});
