import {
  ColumnProps,
  RowProps,
} from 'components/common/PortfolioCollapseTable/PortfolioCollapseTable.d';
import { NameColumn } from '../NameColumn';
import { StarRatingsColumn } from '../StarRatingsColumn';
import { LetterBadge } from 'components/common/Icons/LetterBadge';
import { CheckStatusIndicator } from 'components/common/Icons/CheckStatusIndicator';
import { PurchasePriceIndicator } from '../PurchasePriceIndicator';
import { Option } from 'components/common/Select/Select.d';
import { Box } from 'components/common/Box';
import { TableChip } from 'components/common/TableChip/TableChip';
import { StyledTableChipContainer } from './CustomerOverview.styled';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { PlansecurFundProps } from 'types/Plansecur.d';
import { formatNumberWithComma, parseDate } from 'utils/common';
import { morningstarMedalistRatingOptions } from './options';

export const getDefaultFilterValue = (value: string, options: Option[]) => {
  const [result] = options.filter((option) => option.value === value);
  return result;
};

export const statusOptions: Option[] = [
  {
    value: 'In Kompass aufnehmen',
    name: 'In Kompass aufnehmen',
    chipVariant: TableChipVariant.GREEN,
  },
  {
    value: 'Aus Kompass streichen',
    name: 'Aus Kompass streichen',
    chipVariant: TableChipVariant.RED,
  },
];

export const createRow = (tableData: PlansecurFundProps): RowProps => {
  return {
    id: tableData.id,
    scope_rating: tableData.scope_rating,
    appropriation_of_earnings: tableData.appropriation_of_earnings ?? '-',
    fund_id: tableData.fund_id,
    isin: tableData.isin,
    wkn: tableData.wkn ?? '-',
    name: tableData.name,
    currency: tableData.currency ?? '-',
    company: tableData.company ?? '-',
    category: tableData.category ?? '-',
    regional_focus: tableData.regional_focus ?? '-',
    currency_focus: tableData.currency_focus ?? '-',
    launch_date: tableData.launch_date ? parseDate(tableData.launch_date) : '-',
    aa_percentage: tableData.aa_percentage ?? '-',
    issue_price: tableData.issue_price ?? '-',
    redemption_price: tableData.redemption_price ?? '-',
    price_currency: tableData.price_currency ?? '-',
    price_date: tableData.price_date ? parseDate(tableData.price_date) : '-',
    performance_current_year: tableData.performance_current_year ?? '-',
    performance_1_month: tableData.performance_1_month ?? '-',
    performance_3_months: tableData.performance_3_months ?? '-',
    performance_6_months: tableData.performance_6_months ?? '-',
    performance_1_year: tableData.performance_1_year ?? '-',
    performance_3_years: tableData.performance_3_years ?? '-',
    performance_5_years: tableData.performance_5_years ?? '-',
    performance_10_years: tableData.performance_10_years ?? '-',
    performance_since_edition: tableData.performance_since_edition ?? '-',
    performance_3_years_pa: tableData.performance_3_years_pa ?? '-',
    performance_5_years_pa: tableData.performance_5_years_pa ?? '-',
    performance_10_years_pa: tableData.performance_10_years_pa ?? '-',
    performance_since_edition_pa: tableData.performance_since_edition_pa ?? '-',
    sharpe_ratio_1_year: tableData.sharpe_ratio_1_year ?? '-',
    sharpe_ratio_3_years: tableData.sharpe_ratio_3_years ?? '-',
    sharpe_ratio_5_years: tableData.sharpe_ratio_5_years ?? '-',
    treynor_ratio: tableData.treynor_ratio ?? '-',
    jensen_alpha: tableData.jensen_alpha ?? '-',
    volatility_1_year: tableData.volatility_1_year ?? '-',
    volatility_3_years: tableData.volatility_3_years ?? '-',
    volatility_5_years: tableData.volatility_5_years ?? '-',
    volatility_10_years: tableData.volatility_10_years ?? '-',
    information_ratio: tableData.information_ratio ?? '-',
    fww_fundstars_rating: tableData.fww_fundstars_rating,
    morningstar_medalist_rating: tableData.morningstar_medalist_rating,
    index_fund: tableData.index_fund,
    vl_fund: tableData.vl_fund,
    ffb_online_order_cutoff_time: tableData.ffb_online_order_cutoff_time ?? '-',
    price_fixing_purchase_offset: tableData.price_fixing_purchase_offset,
    price_fixing_sale_offset: tableData.price_fixing_sale_offset,
    custody_fee: tableData.custody_fee,
    portfolio_eligible: tableData.portfolio_eligible,
    savings_plan_eligible: tableData.savings_plan_eligible,
    buy: tableData.buy,
    sell: tableData.sell,
    payment_plan: tableData.payment_plan,
    fund_of_funds: tableData.fund_of_funds,
    volume_in_million_eur: tableData.volume_in_million_eur
      ? formatNumberWithComma(tableData.volume_in_million_eur)
      : '-',
    suitability_assessment_fund_category:
      tableData.suitability_assessment_fund_category ?? '-',
    fng_label: tableData.fng_label ?? '-',
    sustainability_star_rating: tableData.sustainability_star_rating ?? '-',
    eet_template_version: tableData.eet_template_version ?? '-',
    esg_label_or_standard: tableData.esg_label_or_standard ?? '-',
    pornography_exclusion: tableData.pornography_exclusion,
    exclusion_threshold: tableData.exclusion_threshold ?? '-',
    sustainability_preference_sfdr:
      tableData.sustainability_preference_sfdr ?? '-',
    ungc_oecd_violations_considered: tableData.ungc_oecd_violations_considered,
    is_compass_design_request_sent: false,
    is_in_my_portfolio: false,
    present_in_kompass: tableData.present_in_kompass,
    is_watchlisted: tableData.is_watchlisted ?? null,
    user__name: tableData.user__name ?? '-',
    created_at: tableData.created_at ? tableData.created_at : '-',
    requested_by_comment: tableData.requested_by_comment ?? '-',
    requestType: tableData.requestType ?? null,
    requestId: tableData.request_id ?? '-',
    highlightColor: tableData.highlightColor ?? null,
    highlightTooltip: tableData.highlightTooltip ?? null,
    gradientColor: tableData.gradientColor ?? null,
    sfb_related_fund__peer_group:
      tableData?.sfb_related_fund?.peer_group ?? '-',
    sfb_related_fund__rank__rank:
      tableData?.sfb_related_fund?.rank?.rank ?? null,
    quartil: tableData.quartil ?? null,
    sfb_related_fund__volume: tableData.sfb_related_fund?.volume ?? '-',
    sfb_related_fund__track_record:
      tableData.sfb_related_fund?.track_record ?? '-',
    sfb_related_fund__total_active_management_score:
      tableData.sfb_related_fund?.total_active_management_score || null,
    sfb_related_fund__total_risk_score:
      tableData.sfb_related_fund?.total_risk_score || null,
    sfb_related_fund__esg_scores__credibility_score:
      tableData?.sfb_related_fund?.esg_scores &&
      tableData.sfb_related_fund.esg_scores?.credibility_score
        ? tableData.sfb_related_fund.esg_scores?.credibility_score?.toString()
        : null,
    nr_of_funds: tableData?.sfb_related_fund?.rank
      ? tableData?.sfb_related_fund.rank.nr_of_funds
      : null,
    esg_actions: '-',
    sfb_related_fund__return_score__total_score:
      tableData.sfb_related_fund?.return_score.total_score?.label || null,
    status: tableData.status?.status ?? null,
    status_timestamp: tableData.status?.timestamp ?? null,
    source: tableData.source ?? '',
    plansecur_asset_class: tableData.plansecur_asset_class ?? '-',
    calendar_year_2020: tableData.calendar_year_2020 ?? '-',
    calendar_year_2021: tableData.calendar_year_2021 ?? '-',
    calendar_year_2022: tableData.calendar_year_2022 ?? '-',
    calendar_year_2023: tableData.calendar_year_2023 ?? '-',
    calendar_year_2024: tableData.calendar_year_2024 ?? '-',
    quintile_2020: tableData.quintile_2020 ?? null,
    quintile_2021: tableData.quintile_2021 ?? null,
    quintile_2022: tableData.quintile_2022 ?? null,
    quintile_2023: tableData.quintile_2023 ?? null,
    quintile_2024: tableData.quintile_2024 ?? null,
    quintile_sum: tableData.quintile_sum ?? null,
    management_fee_percentage: tableData.management_fee_percentage ?? '-',
    performance_fee_percentage: tableData.performance_fee_percentage ?? '-',
    representative_cost_ex_transaction_percentage:
      tableData.representative_cost_ex_transaction_percentage ?? '-',
    representative_cost_inc_transaction_percentage:
      tableData.representative_cost_ex_transaction_percentage ?? '-',
    investment_goal: tableData.investment_goal ?? '-',
    broker_notes: tableData.broker_notes ?? '-',
    allocation: tableData.allocation ?? '-',
    recommendation_for_action: tableData.recommendation_for_action ?? '-',
    assessment: tableData.assessment ?? '-',
    adjustments: tableData.adjustments ?? '-',
    reviewer_notes: tableData.reviewer_notes ?? '-',
  };
};

export const commonColumns: ColumnProps[] = [
  {
    id: 'name',
    label: 'Name',
    tooltip: 'The name of the item',
    sortable: true,
    renderCell: ({ row, value }) => {
      return <NameColumn variant="small" name={value} type={row.isin} />;
    },
    subColumns: [],
  },
  {
    id: 'stammdaten',
    label: 'Stammdaten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'wkn',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'WKN',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Fondswährung',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
      },
      {
        id: 'company',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Gesellschaft',
        sortable: true,
        minWidth: '160px',
        maxWidth: '160px',
      },
      {
        id: 'category',
        headAlign: 'left',
        cellAlign: 'left',
        label: 'Fondskategorie / Asset-Schwerpunkt',
        sortable: true,
        minWidth: '169px',
        maxWidth: '169px',
      },
      {
        id: 'regional_focus',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Regionenschwerpunkt',
        sortable: true,
        minWidth: '177px',
        maxWidth: '177px',
      },
      {
        id: 'currency_focus',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Währungsschwerpunkt',
        sortable: true,
        minWidth: '182px',
        maxWidth: '182px',
      },
      {
        id: 'plansecur_asset_class',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Plansecur-Assetklasse',
        sortable: true,
        minWidth: '182px',
        maxWidth: '182px',
      },
      {
        id: 'launch_date',
        headAlign: 'center',
        cellAlign: 'left',
        label: 'Auflegung',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'preis',
    label: 'Preis und Kosten',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'aa_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'AA in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'management_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Managementgebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_fee_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Performancegebühr in %',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'representative_cost_ex_transaction_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Representative Cost (ex Transaction) in %',
        sortable: true,
        minWidth: '170px',
        maxWidth: '170px',
      },
      {
        id: 'representative_cost_inc_transaction_percentage',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Representative Cost (inc Transaction) in %',
        sortable: true,
        minWidth: '170px',
        maxWidth: '170px',
      },
      {
        id: 'issue_price',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ausgabepreis',
        sortable: true,
        minWidth: '118px',
        maxWidth: '118px',
      },
      {
        id: 'redemption_price',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Rücknahmepreis',
        sortable: true,
        minWidth: '138px',
        maxWidth: '138px',
      },
      {
        id: 'price_currency',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preiswährung',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'price_date',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preisdatum',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'rendite',
    label: 'Rendite',
    visibleSubColumnsNumber: 4,
    subColumns: [
      {
        id: 'performance_current_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Laufendes Jahr',
        sortable: true,
        minWidth: '130px',
        maxWidth: '130px',
      },
      {
        id: 'performance_1_month',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Monat',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Monate',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_6_months',
        headAlign: 'center',
        cellAlign: 'center',
        label: '6 Monate',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_10_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'performance_since_edition',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Seit Auflage',
        sortable: true,
        minWidth: '108px',
        maxWidth: '108px',
      },
      {
        id: 'performance_3_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_5_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre p.a.',
        sortable: true,
        minWidth: '105px',
        maxWidth: '105px',
      },
      {
        id: 'performance_10_years_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre p.a.',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'performance_since_edition_pa',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Seit Auflage p.a.',
        sortable: true,
        minWidth: '137px',
        maxWidth: '137px',
      },
      {
        id: 'calendar_year_2020',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2020',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'calendar_year_2021',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2021',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'calendar_year_2022',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2022',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'calendar_year_2023',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2023',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
      {
        id: 'calendar_year_2024',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kalenderjahre 2024',
        sortable: true,
        minWidth: '95px',
        maxWidth: '95px',
      },
    ],
  },
  {
    id: 'aktives_anagement',
    label: 'Aktives Management',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'sharpe_ratio_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 1 Jahr',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
      },
      {
        id: 'sharpe_ratio_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 3 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'sharpe_ratio_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sharpe Ratio 5 Jahre',
        sortable: true,
        minWidth: '113px',
        maxWidth: '113px',
      },
      {
        id: 'treynor_ratio',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Treynor Ratio',
        sortable: true,
        minWidth: '116px',
        maxWidth: '116px',
      },
      {
        id: 'jensen_alpha',
        headAlign: 'center',
        cellAlign: 'center',
        label: "Jensen's Alpha",
        sortable: true,
        minWidth: '130px',
        maxWidth: '130px',
      },
    ],
  },
  {
    id: 'volatility',
    label: 'Volatilität',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'volatility_1_year',
        headAlign: 'center',
        cellAlign: 'center',
        label: '1 Jahr',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_3_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '3 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_5_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '5 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'volatility_10_years',
        headAlign: 'center',
        cellAlign: 'center',
        label: '10 Jahre',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
    ],
  },
  {
    id: 'information_ratio_data',
    label: '',
    visibleSubColumnsNumber: 1,
    subColumns: [
      {
        id: 'information_ratio',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Information Ratio',
        sortable: true,
        minWidth: '147px',
        maxWidth: '147px',
      },
    ],
  },
  {
    id: 'externe_ratings',
    label: 'Externe Ratings',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'fww_fundstars_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FWW FundStars(R)',
        sortable: true,
        minWidth: '112px',
        maxWidth: '112px',
        renderCell: ({ value }) => <StarRatingsColumn value={value} />,
      },
      {
        id: 'morningstar_medalist_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Morningstar Medalist Rating',
        sortable: true,
        minWidth: '125px',
        maxWidth: '125px',
        renderCell: ({ value }) => {
          const currentValue = getDefaultFilterValue(
            value,
            morningstarMedalistRatingOptions,
          );
          return !value || value === '-' ? (
            <span>-</span>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: '100%' }}
            >
              <StyledTableChipContainer>
                <TableChip
                  label={value.toLowerCase() as ChipVariant}
                  tableChipVaraint={currentValue.chipVariant}
                />
              </StyledTableChipContainer>
            </Box>
          );
        },
      },
      {
        id: 'scope_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Scope Mutual Fund Rating',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <LetterBadge value={value} />,
      },
    ],
  },
  {
    id: 'abwicklung',
    label: 'Fondsstruktur & Abwicklung',
    visibleSubColumnsNumber: 3,
    subColumns: [
      {
        id: 'appropriation_of_earnings',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Ertragsverwendung',
        sortable: true,
        minWidth: '158px',
        maxWidth: '158px',
      },
      {
        id: 'index_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Indexfonds',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'vl_fund',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'VL-Fonds',
        sortable: true,
        minWidth: '90px',
        maxWidth: '90px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'ffb_online_order_cutoff_time',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FFB-Online\n -Orderschlusszeit',
        sortable: true,
        minWidth: '150px',
        maxWidth: '150px',
      },
      {
        id: 'price_fixing_purchase_offset',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Preisfestste -llung Kauf',
        sortable: true,
        minWidth: '106px',
        maxWidth: '106px',
        renderCell: ({ value }) => <PurchasePriceIndicator value={value} />,
      },
      {
        id: 'custody_fee',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verwahrentgelt',
        sortable: true,
        minWidth: '129px',
        maxWidth: '129px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'portfolio_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Portfoliofähig',
        sortable: true,
        minWidth: '117px',
        maxWidth: '117px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'savings_plan_eligible',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sparplanfähig',
        sortable: true,
        minWidth: '119px',
        maxWidth: '119px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'buy',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Kauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'sell',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Verkauf',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'payment_plan',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Auszahlplan',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'fund_of_funds',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Dachfonds',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'volume_in_million_eur',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Volumen umgerechnet\n in Mio. EUR',
        sortable: true,
        minWidth: '178px',
        maxWidth: '178px',
      },
      {
        id: 'suitability_assessment_fund_category',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Angemessenheitsbeurteilung - Fondskategorie',
        sortable: true,
        minWidth: '225px',
        maxWidth: '225px',
      },
    ],
  },
  {
    id: 'nachhaltigkeit',
    label: 'Nachhaltigkeit',
    visibleSubColumnsNumber: 2,
    subColumns: [
      {
        id: 'fng_label',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'FNG-Siegel',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_star_rating',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Sterne',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'eet_template_version',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'EET Template Version',
        sortable: true,
        minWidth: '120px',
        maxWidth: '120px',
      },
      {
        id: 'esg_label_or_standard',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'ESG Label or Standard',
        sortable: true,
        minWidth: '110px',
        maxWidth: '110px',
      },
      {
        id: 'pornography_exclusion',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Exclusion PorNeingraphie',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
      {
        id: 'exclusion_threshold',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'Exclusion Threshold',
        sortable: true,
        minWidth: '93px',
        maxWidth: '93px',
      },
      {
        id: 'sustainability_preference_sfdr',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'NH-Präferenz gem. SFDR',
        sortable: true,
        minWidth: '120px',
        maxWidth: '120px',
      },
      {
        id: 'ungc_oecd_violations_considered',
        headAlign: 'center',
        cellAlign: 'center',
        label: 'UNGC Principles Or OECD Guidelines Violations Considered',
        sortable: true,
        minWidth: '250px',
        maxWidth: '250px',
        renderCell: ({ value }) => <CheckStatusIndicator value={value} />,
      },
    ],
  },
];
