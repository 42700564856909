import React, { forwardRef, useState } from 'react';
import { DateInputProps } from './DateInput.d';
import { Input } from '../Input';

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ label, maxDate, minDate, value, onDateChange, error, ...props }, ref) => {
    const [dateError, setDateError] = useState<string | undefined>(error);

    const formatDateForInput = (date: Date | null): string => {
      if (!date) return '';

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    const validateDate = (inputValue: string) => {
      if (!inputValue) {
        setDateError(undefined);
        onDateChange?.(null);
        return;
      }

      const [year, month, day] = inputValue.split('-').map(Number);
      const date = new Date(year, month - 1, day, 12, 0, 0);

      if (isNaN(date.getTime())) {
        setDateError('Ungültiges Datum');
        return;
      }

      if (maxDate && date > maxDate) {
        setDateError(
          `Datum darf nicht später als ${maxDate.toLocaleDateString(
            'de-DE',
          )} sein`,
        );
        return;
      }

      if (minDate && date < minDate) {
        setDateError(
          `Datum darf nicht früher als ${minDate.toLocaleDateString(
            'de-DE',
          )} sein`,
        );
        return;
      }

      setDateError(undefined);
      onDateChange?.(date);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      validateDate(e.target.value);
      props.onChange?.(e);
    };

    return (
      <Input
        type="date"
        label={label}
        error={dateError || error}
        ref={ref}
        value={value ? formatDateForInput(new Date(value)) : ''}
        {...props}
        onChange={handleChange}
      />
    );
  },
);
