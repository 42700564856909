import { FC } from 'react';
import ReactDOM from 'react-dom';
import {
  Backdrop,
  Wrapper,
  StyledContainer,
  StyledModal,
  CloseButton,
  StyledHeadline,
  StyledSubtitle,
} from './Modal.styled';
import { ModalProps } from './Modal.d';
import { Box } from '../Box';
import { useShortcuts } from 'hooks/useShortcuts';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';
import { Typography } from '../Typography';
import { HelpBox } from '../HelpBox';

export const Modal: FC<ModalProps> = ({
  modalRef,
  children,
  isShown,
  onClose,
  withCloseButton = false,
  withHelpBox = false,
  helpBoxText,
  className,
  title,
  subtitle,
  header,
  ...props
}) => {
  useShortcuts(isShown, onClose);

  const modal = (
    <Box ref={modalRef}>
      <Backdrop onClick={onClose} />
      <Wrapper aria-modal tabIndex={-1} role="dialog" className={className}>
        <StyledContainer>
          <StyledModal
            p={4}
            display="grid"
            gridAutoFlow="row"
            gridGap={0.2}
            {...props}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={header ? 'flex-start' : 'baseline'}
            >
              {header && header}
              {(title || subtitle) && (
                <Box display="grid" gridAutoFlow="row" gridGap={0.2}>
                  {title &&
                    (subtitle ? (
                      <StyledHeadline variant="h5">{title}</StyledHeadline>
                    ) : (
                      <Typography variant="h4">{title}</Typography>
                    ))}
                  {subtitle && (
                    <StyledSubtitle
                      variant="body"
                      color={COLORS.typography.placeholder}
                    >
                      {subtitle}
                    </StyledSubtitle>
                  )}
                </Box>
              )}
              {withCloseButton && (
                <CloseButton onClick={onClose}>
                  <CloseIcon stroke={COLORS.typography.placeholder} />
                </CloseButton>
              )}
            </Box>
            {children}
          </StyledModal>
        </StyledContainer>
        {withHelpBox && <HelpBox />}
      </Wrapper>
    </Box>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
