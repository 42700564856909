import styled from 'styled-components';
import { Box } from '../Box';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { Typography } from '../Typography';

export const StyledContainer = styled(Box)`
  border-radius: 16px;
  background-color: ${COLORS.background.secondary};
  overflow-y: auto;
  transform: none;
`;

export const Wrapper = styled(Box)`
  position: fixed;
  z-index: 700;
  outline: 0;
  transform: none;
  width: 100%;
  top: 0;
  left: 0;

  ${SIZES.media.tablet} {
    left: unset;
    width: min-content;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
`;

export const Backdrop = styled(Box)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: unset;
  border: none;
  justify-self: end;

  &:hover {
    cursor: pointer;

    svg {
      stroke: ${COLORS.typography.caption};
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledModal = styled(Box)`
  max-height: calc(100vh - 65px);

  ${SIZES.media.tablet} {
    max-height: calc(100vh - 175px);
  }
`;

export const StyledHeadline = styled(Typography)`
  font-size: 24px;
  line-height: 34px;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
`;
